import React from "react";
import "../Header.scss";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../../../assets/img/imgs-carousel/carousel_1.png";
import img2 from "../../../assets/img/imgs-carousel/carousel_2.png";
import img3 from "../../../assets/img/imgs-carousel/carousel_3.png";
import { Spinner } from "react-bootstrap";

const HeaderCarousel = () => {
  const [isImage1Loaded, setIsImage1Loaded] = React.useState(false);

  React.useEffect(() => {
    const image1 = new Image();
    image1.onload = () => setIsImage1Loaded(true);
    image1.src = img1;
    return () => {
      image1.onload = null;
    };
  }, []);

  if (!isImage1Loaded) {
    return (
      <div id="loader">
        <Spinner variant="light" id="loaderSpinner" />
      </div>
    );
  }

  return (
    <Carousel fade className="carousel" controls={false} indicators={false}>
      <Carousel.Item interval={3000}>
        <img className="carouselImage" src={img1} alt="IMG1" loading="lazy" />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className="carouselImage" src={img2} alt="IMG2" loading="lazy" />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img className="carouselImage" src={img3} alt="IMG3" loading="lazy" />
      </Carousel.Item>
    </Carousel>
  );
};

export { HeaderCarousel };
