import React from "react";
import { Button } from "react-bootstrap";
import "./Header.scss";
import { HeaderCarousel } from "./HeaderCarousel";

const Header = () => {
  return (
    <div id="header" className="container-fluid">
      <HeaderCarousel />
      <div id="headerContent" className="container">
        <h1 id="title">Fulcrumg</h1>
        <h4 id="subtitle">
          En Coyhaique, a 25 minutos del centro de la ciudad
          <br /> Invierte en la Patagonia
        </h4>
        <Button
          id="moreInfoButton"
          className="homeButton"
          variant="outline-light"
          size="lg"
          href="#proyectos"
        >
          Más información
        </Button>
        <Button
          id="homeContactButton"
          className="homeButton"
          size="lg"
          href="#contacto"
        >
          Contáctanos
        </Button>
      </div>
    </div>
  );
};

export { Header };
