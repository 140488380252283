import React, { useState } from "react";
import "./Proyectos.scss";
import image1 from "../../assets/img/imgs-proyectos/DJI_0678mini.jpg";
import image2 from "../../assets/img/imgs-proyectos/DJI_0152.jpg";
import image3 from "../../assets/img/imgs-proyectos/rivera-condor.jpg";
import image4 from "../../assets/img/imgs-proyectos/los-lagos.jpg";
import image5 from "../../assets/img/imgs-proyectos/oportunidades.jpeg";
import { Card, Button } from "react-bootstrap";

const Proyectos = () => {
  const handleSeeProyect = () => {};

  //Posible status: active, selled, comming
  const projects = [
    {
      title: "Fundo Armadillo",
      img: image1,
      url: "https://fundoarmadillo.cl",
      status: "active",
      showUrlButton: true,
      description:
        "72 Exclusivas parcelas en una de las zonas del Valle Simpsons más hermosas de la patagonia. A minutos del aeropuerto, la ciudad y los principales lagos de la zona.",
    },
    {
      title: "Fundo Huemul",
      img: image2,
      url: "https://www.fundohuemul.cl/",
      status: "active",
      showUrlButton: true,
      description:
        "34 Parcelas en el corazón del sector denominado La Galera, a minutos de la carretera austral, aeropuerto y la ciudad. Terreno con Vertientes.",
    },
    {
      title: "Rivera Condor",
      img: image3,
      url: "https://www.riveracondor.cl/",
      status: "comming",
      showUrlButton: true,
      description:
        "Proyecto ubicado en la Patagonia Chilena, rodeado de los sectores más bellos del sur de chile, a orillas del río Simpson.",
    },
    {
      title: "Región de los Lagos",
      img: image4,
      url: "",
      status: "comming",
      showUrlButton: false,
      description:
        "Proyecto ubicado en la Región de los Lagos, muy cerca de los mejores atractivos turísticos de la zona.",
    },
    {
      title: "Oportunidades",
      img: image5,
      url: "",
      status: "comming",
      showUrlButton: false,
      buttonText: "Ir a oportunidades",
      description:
        "Descubre aquí parcelas a precio remate en diversas regiones de Chile.",
    },
  ];

  return (
    <section id="proyectos" className="container-fluid">
      <div className="container" id="contentContainer">
        <h2 id="title">Proyectos</h2>
        <div id="containerProyectos">
          {projects.map((project, i) => (
            <Card id="cardHeader" key={i}>
              {project.status === "comming" && (
                <div id="cardAlert" className="commingAlert">
                  <p>Próximamente</p>
                </div>
              )}
              {project.status === "selled" && (
                <div id="cardAlert" className="selledAlert">
                  <p>Vendido</p>
                </div>
              )}

              <Card.Img variant="top" src={project.img} className="cardImage" />
              <Card.Body
                style={{
                  height: 250,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Card.Title className="classTitle">{project.title}</Card.Title>
                <Card.Text>{project.description}</Card.Text>
                {project.showUrlButton ? (
                  <a
                    href={project.url}
                    target="_blank"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textDecoration: "none",
                    }}
                  >
                    <Button className="cardButton">
                      {project.buttonText || "Ir al sitio del proyecto"}
                    </Button>
                  </a>
                ) : (
                  <div style={{ height: 38 }}></div>
                )}
              </Card.Body>
            </Card>
          ))}
          <div className="filling-empty-space-childs " />
        </div>
      </div>
      <div id="backgroundColor"></div>
    </section>
  );
};

export { Proyectos };
