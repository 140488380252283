import React from "react";
import "./Home.scss";
import { Nosotros } from "../Nosotros";
import { Proyectos } from "../Proyectos";
import { Videos } from "../Videos";
import { Experiencia } from "../Experiencia";
import { Contacto } from "../Contacto";
import backgroundImage from "../../assets/img/greywood.jpeg";
import { Header } from "../Header";

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <Nosotros />
      <Proyectos />
      <Videos />
      {/* <Experiencia /> */}
      <Contacto />
      <img src={backgroundImage} alt="img-fondo" id="backgroundImage" />
    </React.Fragment>
  );
};

export { Home };
