import React, { useState } from "react";
import { Image, Alert, Spinner } from "react-bootstrap";
import "./Contacto.scss";
import imagenContacto from "../../assets/img/imagen-contacto.jpg";
import { Formulario } from "../../components/common/Formulario";

const Contacto = () => {
  const [onCharge, setOnCharge] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <section id="contacto" className="container-fluid">
      <div className="container">
        {success && (
          <Alert key={"success"} variant={"success"}>
            Tu mensaje fue enviado con éxito, pronto te contactaremos.
          </Alert>
        )}
        {error && (
          <Alert key={"danger"} variant={"danger"}>
            Hubo un error al enviar tu mensaje, por favor inténtalo nuevamente
          </Alert>
        )}
        <h2 id="title">Contacto</h2>
        <div id="contentContainer">
          <div id="formContainer">
            {onCharge ? (
              <Spinner animation="border" variant="dark" id="spinner" />
            ) : (
              <Formulario
                setOnCharge={setOnCharge}
                setError={setError}
                setSuccess={setSuccess}
              />
            )}
          </div>
          <div id="imageContainer">
            <Image
              id="imagenContacto"
              src={imagenContacto}
              alt="Imagen contacto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Contacto };
