import React, { useEffect, useState } from "react";
import "./Videos.scss";
import axios from "axios";
import { youtubeInfo } from "../../utils/externalVariables";
import YoutubeCarousel from "./YoutubeCarousel";

const Videos = () => {
  const youtubeChanelLastVideosApi = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCblZWHYK0f9c2EmUxZ2mJfg&maxResults=24&order=date&type=video&key=${youtubeInfo}`;
  const [videoList, setVideoList] = useState([]);

  const getPlaylistVideos = async () => {
    const videoListResponse = await axios.get(youtubeChanelLastVideosApi);
    setVideoList(videoListResponse.data.items);
  };

  useEffect(() => {
    getPlaylistVideos();
  }, []);

  return (
    <section id="videos" className="container-fluidß">
      <div className="container">
        <div id="contentContainer">
          <div id="textContainer">
            <h2 id="title">Videos</h2>
          </div>
          <div id="youtubeListContainer">
            <YoutubeCarousel items={videoList} />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Videos };
