import React, { useState } from "react";
import "./Nosotros.scss";
import riverImage from "../../assets/img/rio-ancho.jpg";

const Nosotros = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleClose = () => {
    setShowVideoModal(false);
  };

  return (
    <section id="nosotros" className="container-fluid">
      <div className="container">
        <div id="contentContainer">
          <div id="textContainer">
            <h2 id="title">Nosotros</h2>
            <h5>
              Nuestra visión es permitir a las personas coexistir con la
              naturaleza en un ambiente de adecuada conservación.
              <br /> <br />
              La Patagonia Chilena, uno de los lugares más bellos del mundo,
              permite no sólo por su geografía, sino que además por su Flora y
              Fauna, unir a la familia en un ambiente de conservación con el
              ecosistema.
              <br /> <br />
              Abundancia de recurso hídrico, y alta plusvalía es lo que destaca
              a nuestros proyectos. Todos aprobados y autorizados por el SAG
              conforme a normativa vigente.
            </h5>
            {/* <Button
            id="videoButton"
            variant="dark"
            size="lg"
            onClick={() => setShowVideoModal(true)}
          >
            Ver video del proyecto
          </Button> */}
          </div>
          <img id="riverImage" src={riverImage} alt="imagen-proyecto" />
        </div>
      </div>
    </section>
  );
};

export { Nosotros };
